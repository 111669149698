<template>
    <div class="showShop">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>下载中心</el-breadcrumb-item>
            <el-breadcrumb-item>未命名商品名称</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="content">
            <div class="img shoufei">
                <img src="../assets/image/showShop.png" alt="" />
            </div>
            <div class="text">
                <span>系列课程</span>
                <p>气象科学实验系列</p>
                <ul>
                    <li>4-5岁</li>
                    <li>共2册</li>
                    <li>222人使用</li>
                </ul>

                <button class="buy">前往购买</button>
                <button class="down">立即下载</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.showShop {
    width: 1280px;
    margin: 0 auto;
    padding: 10px 0;

    > .el-breadcrumb {
        padding: 20px 30px;
        background: #e5e5e5;
        border-radius: 10px 10px 0px 0px;
    }

    > .content {
        height: 500px;
        border-radius: 10px;
        padding: 20px 10px 50px;
        background: #ffffff;
        box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.3);
        border-radius: 10px;
        box-sizing: border-box;

        > .img {
            display: inline-block;
            position: relative;
            > img {
                height: 100%;
            }

            &.shoufei::after {
                content: "";
                position: absolute;
                display: block;
                width: 76px;
                height: 76px;
                top: 0;
                right: 0;
                background-image: url("../assets/image/shoufei.png");
            }
            &.mianfei::after {
                content: "";
                position: absolute;
                display: block;
                width: 76px;
                height: 76px;
                top: 0;
                right: 0;
                background-image: url("../assets/image/mianfei.png");
            }
        }
        > .text {
            display: inline-block;
            vertical-align: top;
            padding: 30px 0 30px 40px;
            height: 100%;
            box-sizing: border-box;
            position: relative;

            > span {
                display: inline-block;
                font-size: 23px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #fffefe;
                padding: 2px 5px;
                background: linear-gradient(0deg, #fbd936 0%, #fe8b1b 100%);
                border-radius: 5px;
            }

            > p {
                margin: 0;
                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #000000;
                line-height: 68px;
            }

            > ul {
                margin: 0;
                > li {
                    font-size: 19px;
                    font-family: Microsoft YaHei;
                    font-weight: 800;
                    color: #737373;
                    line-height: 32px;
                }
            }

            > button {
                position: absolute;
                left: 40px;
                bottom: 50px;
                border: none;
                font-size: 19px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                box-sizing: border-box;
                padding: 0 15px;
                border-radius: 50%;
                width: 78px;
                height: 78px;

                &.buy {
                    background: linear-gradient(0deg, #fbd435 0%, #fe931e 100%);
                }

                &.down {
                    left: 140px;
                    background: linear-gradient(0deg, #5ff2fe 0%, #0679e5 100%);
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
}
</style>